import xss from 'xss';
import { ImxToken } from '@/services/backend/types';

export function shortWalletAddress(walletAddress?: string): string {
  if (!walletAddress) {
    return '';
  }
  return `${walletAddress.substring(0, 5)}...${walletAddress.slice(38)}`;
}

export function shortEmailAddress(emailAddress?: string | null): string {
  if (!emailAddress) {
    return '';
  }
  return `${emailAddress.substring(0, 10)}...`;
}

export function ethFormatter(eth = 0): string {
  return eth.toFixed(3).toString().replace('.', ',') + ' ETH';
}

export function audFormatter(aud = 0): string {
  return (
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    }).format(aud) + ' AUD'
  );
}

export function isNowBefore(dateString: string) {
  const nowGMT = new Date().toISOString();
  const date0 = new Date(nowGMT);
  const date1 = new Date(dateString);
  return date0 < date1;
}

export function dateFormatter(date: string, options: Intl.DateTimeFormatOptions = {}) {
  return new Date(date).toLocaleString('en-AU', { ...options, timeZone: 'Australia/Sydney' });
}

export const twoDigitsFormatter = (num: number) => num.toString().padStart(2, '0');

export const jsonToString = (o: unknown) => (o ? JSON.stringify(o) : undefined);
export const jsonParse = (o: unknown) => (o ? JSON.parse(JSON.stringify(o)) : undefined);

export function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const base64Decode = (str: string): string => {
  try {
    return Buffer.from(str, 'base64').toString('binary');
  } catch (e) {
    console.log('base64Decode.error:', e);
    return '';
  }
};
export const base64Encode = (str: string): string => Buffer.from(str, 'binary').toString('base64');

export function scrollToTop(): void {
  setTimeout(() => window.scroll({ top: 0, left: 0 }), 100);
}

export function reloadCountdown(localStorageKey: string) {
  const lastReload = localStorage.getItem(localStorageKey);
  if (!lastReload) {
    localStorage.setItem(localStorageKey, new Date().getTime().toString());
    window.location.reload();
    return;
  }

  const RELOAD_EACH_60_SECS = 60 * 1000;
  const _5_SECS = 5 * 1000;

  const diff =
    new Date(RELOAD_EACH_60_SECS + parseInt(lastReload)).valueOf() - new Date().valueOf();
  if (lastReload && diff > 0) {
    setTimeout(() => reloadCountdown(localStorageKey), _5_SECS);
  } else {
    localStorage.setItem(localStorageKey, new Date().getTime().toString());
    window.location.reload();
  }
}

export const sortImxTokensById = (a: ImxToken, b: ImxToken) =>
  parseInt(a?.token_id ?? 0) - parseInt(b?.token_id ?? 0);

export function nftImagePosterSrc(nft?: ImxToken) {
  return nft?.image_url ?? '/images/no-image.png';
}

export function nftVideoSrc(nft?: ImxToken) {
  return nft?.animation_url ?? nft?.image_url.replace('.jpg', '.mp4');
}

export function playInVideoTag() {
  document?.querySelectorAll('video')?.forEach((video) => {
    if (video.paused) {
      void video.play();
    }
  });
}

export function xssSanitize(text: string) {
  return xss(text, {
    whiteList: {}, // empty, means filter out all tags
    stripIgnoreTag: true, // filter out all HTML not in the whitelist
    stripIgnoreTagBody: ['script'], // the script tag is a special case, we need to filter out its content
    allowCommentTag: false,
  });
}
