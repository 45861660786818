import { NextApiRequest, NextApiResponse } from 'next';
import { Session } from 'next-auth';
import { JWT } from 'next-auth/jwt';

export enum NetworkName {
  homestead = 'homestead',
  goerli = 'goerli',
}

export const isProduction = process.env.NEXT_PUBLIC_CURRENT_ENV === 'production';
export const isTest = process.env.NEXT_PUBLIC_CURRENT_ENV === 'test';

interface Chain {
  id: number;
  name: 'Ethereum' | 'Goerli';
  network: NetworkName;
}
export const _mainnet: Chain = {
  id: 1,
  name: 'Ethereum',
  network: NetworkName.homestead,
};
export const _goerli: Chain = {
  id: 5,
  name: 'Goerli',
  network: NetworkName.goerli,
};

export const currentChain = isProduction ? _mainnet : _goerli;

export enum LoginProvider {
  MAGIC_LINK = 'magic_link',
  METAMASK = 'metamask',
  COINBASE = 'coinbase',
}

export type SessionCustom = Session & {
  network?: NetworkName;
  loginProvider?: LoginProvider;
  walletAddress: string;
};

export type JWTCustom = JWT & {
  network?: NetworkName;
  loginProvider?: LoginProvider;
  walletAddress?: string;
};

export type HttpMethod = 'GET' | 'POST';

export interface ErrorMessage {
  code: number;
  error: string;
  details?: string;
}

export interface WithNextApiHandlerParams {
  auth?: boolean;
  allowedMethods: HttpMethod[];
  handler: (req: NextApiRequest, res: NextApiResponse, session?: SessionCustom) => Promise<any>;
}

export enum Routes {
  mint = '/mint',
  landing_page = '/landing-page',
  nft = '/nft',
  surprise = '/surprise',
  thanks = '/thanks',
  period_ended = '/period-ended',
}

export interface Invite {
  name: string;
  email: string;
  valid?: boolean;
}

export const PublicRoutes = [Routes.landing_page];
export const PrivateRoutes = [
  Routes.mint,
  Routes.nft,
  Routes.surprise,
  Routes.thanks,
  Routes.period_ended,
];

export const DefaultApiGetCacheSeconds = 60 * 5; // 5 min
export const DefaultApiGetStaleSeconds = 60 * 4; // 4 min
