// import { detect } from 'detect-browser';
import { ReactNode } from 'react';
import Skeleton from 'react-loading-skeleton';
import { TextPrimary } from '@/components/TextPrimary/TextPrimary.component';

interface CardProps {
  icon?: ReactNode;
  iconSize?: 'sm' | 'lg';
  title?: string;
  text?: string | ReactNode;
  children: ReactNode;
}

export const Card = ({ iconSize = 'sm', ...props }: CardProps) => {
  const iconSizeClasses = () => {
    if (iconSize === 'sm') {
      return 'w-12 h-10 2md:w-20 2md:h-[72px]';
    }
    if (iconSize === 'lg') {
      return 'w-32 h-20 2md:w-64 2md:h-36';
    }

    return '';
  };

  return (
    <div className="max-w-[847px] w-full mx-auto bg-white rounded-xl justify-center flex flex-col gap-7 items-center p-7 2md:p-20">
      {props.icon && <div className={`mb-2 ${iconSizeClasses()}`}>{props.icon}</div>}
      {props.title && <TextPrimary>{props.title}</TextPrimary>}
      {props.text && (
        <div className="text-center p-4 2md:p-8 2md:text-2xl max-w-[650px]">{props.text}</div>
      )}
      {props.children}
    </div>
  );
};

export const CardSkeleton = () => {
  return (
    <div className="max-w-[847px] h-[400px] min-h-[400px] w-full mx-auto flex flex-col items-center">
      <Skeleton
        height="100%"
        containerClassName="flex-grow w-full"
        baseColor="#d6d6d6"
        highlightColor="#ecebeb"
        style={{ borderRadius: '0.75rem' }}
      />
    </div>
  );
};
