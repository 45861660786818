import IconLinkedin from '@/public/images/icon-linkedin.svg';
import IconTwitter from '@/public/images/icon-twitter.svg';

export function Footer() {
  return (
    <footer className="flex justify-between flex-wrap items-center mb-[50px] w-full mx-auto gap-5 2md:gap-0">
      <div className="max-w-[96px] max-h-[29px] 2md:max-w-[136px] 2md:max-h-[42px] min-w-[96px] min-h-[30px]">
        <img src="/images/logo-bottom.png" alt="Moonshot" />
      </div>

      <div className="text-xs 2md:text-base">All rights reserved</div>

      <div className="flex items-center flex-wrap justify-center -order-3 2md:order-none w-full 2md:w-auto gap-4 2md:gap-3">
        <div className="w-full 2md:w-auto">
          <a
            href="https://moonshotgaragelabs.com"
            className="flex justify-center text-transparent bg-gradient-to-br from-primary-300 via-primary-600 to-secondary-600 bg-clip-text"
            target="_blank"
            rel="noreferrer"
          >
            moonshotgaragelabs.com
          </a>
        </div>
        <div className="flex justify-center gap-3 -order-1 2md:order-none">
          <a href="https://twitter.com/MoonshotGL" target="_blank" rel="noreferrer">
            <IconTwitter width={30} heigth={30} />
          </a>
          <a
            href="https://br.linkedin.com/company/moonshotgaragelabs"
            target="_blank"
            rel="noreferrer"
          >
            <IconLinkedin width={30} heigth={30} />
          </a>
        </div>
      </div>
    </footer>
  );
}
