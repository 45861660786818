import { Dropdown } from 'flowbite-react';
import { signOut } from 'next-auth/react';
import { MouseEventHandler, useEffect } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { AiOutlineCopy, AiOutlineLogout } from 'react-icons/ai';
import { useWindowSize } from 'usehooks-ts';
import { useDisconnect } from 'wagmi';
import { useSessionCustom } from '@/lib/hooks/useSessionCustom';
import { shortEmailAddress, shortWalletAddress } from '@/lib/utils';

interface ButtonUserLoggedProps {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  loading?: boolean;
  disabled?: boolean;
}

export const ButtonUserLogged = (_props: ButtonUserLoggedProps) => {
  const { session, isDisconnected, isLoading } = useSessionCustom();
  const { disconnect } = useDisconnect();

  const screen = useWindowSize();

  useEffect(() => {
    // fix for dropdown not hidden and just invisible (error in Dropdown component from flowbite-react)
    const classList = document?.querySelector('[data-testid="flowbite-tooltip"]')?.classList;
    if (classList?.contains('invisible') && !classList?.contains('hidden')) {
      classList?.add('hidden');
    }
  }, [screen]);

  if (isLoading || isDisconnected) {
    return null;
  }
  const shortEmail = shortEmailAddress(session?.user?.email);
  const shortWallet = shortWalletAddress(session?.walletAddress);
  const userLogged = shortEmail || shortWallet;

  return (
    <div
      style={{
        borderRadius: '200px',
        padding: '1px',
        background: 'linear-gradient(315deg, #FF5CEE -0.05%, #6442E3 49.43%, #41B5E7 99.95%)',
      }}
    >
      <div>
        <Dropdown
          label={userLogged}
          style={{
            margin: '1px',
            color: 'black',
            borderRadius: '200px',
            border: '2px solid transparent',
            background: 'rgba(255, 255, 255, 0.8)',
          }}
        >
          {session?.user?.email && (
            <Dropdown.Item>
              <div
                style={{
                  whiteSpace: 'normal',
                  overflowWrap: 'break-word',
                }}
                className="block text-sm max-w-[300px]"
              >
                <strong>Email:</strong> {session.user?.email}
              </div>
            </Dropdown.Item>
          )}
          <Dropdown.Item>
            <CopyToClipboard text={session?.walletAddress}>
              <div className="flex gap-10 justify-between w-full">
                <div className="block text-sm truncate">
                  <strong>Wallet:</strong> {shortWallet}
                </div>
                <div>
                  <AiOutlineCopy />
                </div>
              </div>
            </CopyToClipboard>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={async () => {
              disconnect();
              void signOut({
                callbackUrl: '/',
              });
            }}
          >
            <div className="w-full">
              <div className="flex gap-10 justify-between">
                <div className="block text-sm font-medium truncate">Log out</div>
                <div>
                  <AiOutlineLogout />
                </div>
              </div>
            </div>
          </Dropdown.Item>
        </Dropdown>
      </div>
    </div>
  );
};
