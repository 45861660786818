import NextHead from 'next/head';
import { useRouter } from 'next/router';
import type { PropsWithChildren } from 'react';
import React from 'react';

type HeadMetaTagsProps = {
  title?: string;
  description?: string;
  image?: string;
  url?: string;
  canonicalUrl?: string;
  seoIndexPage?: boolean;
  seoFollowPage?: boolean;
};

const siteUrl = process.env.NEXT_PUBLIC_NEXTAUTH_URL;
const imageUrl = '/images/email/og-default.png';
const siteName = 'Moonshot Christmas Carbon NFT 2022';

export default function HeadMetaTags({
  title = siteName,
  description = 'Moonshot, an Australian Web3 Innovation Lab, wishes you Merry Christmas with something a little different this year, a Christmas Carbon Offset NFT.',
  image: _image = '',
  url: _path = '',
  canonicalUrl: canonical = '',
  children,
  seoIndexPage = true,
  seoFollowPage = true,
}: PropsWithChildren<HeadMetaTagsProps>) {
  const router = useRouter();
  const image = !_image ? `${siteUrl}${imageUrl}` : _image;
  const path = !_path ? router.asPath : _path;
  const url = `${siteUrl}${path}`;
  const canonicalUrl = canonical || url;

  if (['/500', '/404'].includes(path)) {
    seoIndexPage = false;
    seoFollowPage = false;
  }

  const robotsTagValue = `${seoIndexPage ? '' : 'no'}index, ${seoFollowPage ? '' : 'no'}follow`;

  return (
    <NextHead>
      <title key="pageTitle">{title}</title>
      <meta charSet="UTF-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />

      <link rel="canonical" href={canonicalUrl} key="canonical" />
      <meta name="robots" content={robotsTagValue} key="robotsContent" />

      <meta name="title" content={title} key="title" />
      <meta name="description" content={description} key="description" />

      {/* Open Graph / Facebook */}
      <meta property="og:image" content={image} key="pageOgImage" />
      <meta property="og:url" content={url} key="pageOgUrl" />
      <meta property="og:type" content="website" key="pageOgType" />
      <meta property="og:site_name" content={siteName} key="pageOgSiteName" />
      <meta property="og:title" content={title} key="pageOgTitle" />
      <meta property="og:description" content={description} key="pageOgDescription" />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" key="pageTwitterCard" />
      <meta property="twitter:title" content={title} key="pageTwitterTitle" />
      <meta property="twitter:description" content={description} key="pageTwitterDescription" />
      <meta property="twitter:image" itemProp="image" content={image} key="pageTwitterImage" />
      <meta property="twitter:url" content={url} key="pageTwitterUrl" />

      {children}
    </NextHead>
  );
}
