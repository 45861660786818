import { ReactNode } from 'react';
import Skeleton from 'react-loading-skeleton';
import { TextPrimary } from '@/components/TextPrimary/TextPrimary.component';

interface InfoProps {
  title: string;
  img?: ReactNode;
  reverse?: boolean;
  children: ReactNode;
}

export const Info = (props: InfoProps) => {
  return (
    <div
      className={`flex justify-between items-center gap-6 ${props.reverse && 'flex-row-reverse'}`}
    >
      <div className="flex flex-col gap-6">
        <TextPrimary>{props.title}</TextPrimary>
        <div
          className={
            'flex flex-col gap-6 max-w-[616px] font-normal text-base 2md:text-2xl leading-7 2md:leading-10'
          }
        >
          {props.children}
        </div>
      </div>
      {props.img && (
        <div className="w-full max-w-[608px] hidden md:flex justify-center items-center">
          <div
            className={`${
              props.reverse
                ? 'max-w-[330px] max-h-[261px] p-8 2md:p-4'
                : 'max-w-[616px] max-h-[529px] 2md:block'
            }`}
          >
            {props.img}
          </div>
        </div>
      )}
    </div>
  );
};

export const InfoSkeleton = () => {
  return (
    <div className="flex justify-between items-center gap-6">
      <div className="flex flex-col gap-6 w-full max-w-[500px]">
        <Skeleton baseColor="#d6d6d6" highlightColor="#ecebeb" width="80%" height={36} />
        <div
          className={
            'flex flex-col gap-6 max-w-[616px] font-normal text-base 2md:text-2xl leading-7 2md:leading-10'
          }
        >
          <p>
            <Skeleton baseColor="#d6d6d6" highlightColor="#ecebeb" count={2} />
          </p>
          <p>
            <Skeleton baseColor="#d6d6d6" highlightColor="#ecebeb" count={1} />
          </p>
        </div>
      </div>
    </div>
  );
};
