import Link from 'next/link';
import { useContext } from 'react';
import { ButtonUserLogged } from '@/components/Button/ButtonUserLogged.component';
import { AppContext } from '@/contexts/AppContext';
import { Routes } from '@/types/types';

interface HeadProps {
  showLoginButton?: boolean;
}

export const Head = ({ showLoginButton = true }: HeadProps) => {
  const appContext = useContext(AppContext);

  return (
    <div className="flex justify-between mt-[50px]">
      <Link
        href={
          appContext.session?.walletAddress
            ? appContext.myNfts?.status === 'done'
              ? Routes.nft
              : Routes.mint
            : Routes.landing_page
        }
      >
        <div
          className={`${
            showLoginButton ? 'w-[130px] h-[48px] md:w-[207px] md:h-[64px]' : 'max-w-[207px]'
          } `}
        >
          <img src="/images/logo-top.png" alt="Moonshot Carbon" />
        </div>
      </Link>
      <div>{showLoginButton && <ButtonUserLogged />}</div>
    </div>
  );
};
