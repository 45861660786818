/* eslint-disable react/jsx-key */
import { ReactNode } from 'react';
import { Routes } from '@/types/types';

export type FaqItem = {
  key: string;
  question: string;
  answer: ReactNode;
};

export interface FaqTopic {
  id: string;
  title: string;
  text: string;
  href: string;
  items: FaqItem[];
}

export const faqTopics: FaqTopic[] = [
  {
    id: '1',
    title: 'FAQ',
    text: 'Frequently Asked Question',
    href: Routes.landing_page,
    items: [
      {
        key: '1_1',
        question: 'Who are Moonshot?',
        answer: (
          <p>
            Moonshot is an Australian Web3 Innovation Lab delivering creatively diverse and
            technically progressive NFT experiences. We support brands creating unique, impactful
            and relevant NFT led campaigns and release our own independent projects (like our carbon
            offset project coming early next year).
          </p>
        ),
      },
      {
        key: '1_2',
        question: 'Why are Moonshot doing this?',
        answer: (
          <p>
            This year, {"we're"} not just spreading Christmas Joy, {"we're"} spreading the joy of
            Web 3.
          </p>
        ),
      },
      {
        key: '1_3',
        question: 'Why are the NFTs minted onto ImmutableX?',
        answer: (
          <p>
            ImmutableX offers a scalable, secure & carbon-neutral platform designed to support the
            next generation of NFTs.
          </p>
        ),
      },
      {
        key: '1_4',
        question: 'What is an NFT?',
        answer: (
          <p>
            A non-fungible token is a unique digital identifier that cannot be copied, substituted,
            or subdivided. It is unique and recorded on the blockchain.
          </p>
        ),
      },
      {
        key: '1_5',
        question: 'Are the NFTs free?',
        answer: (
          <>
            <p>Yes. Moonshot is gifting this NFT to you for Christmas, enjoy!</p>
          </>
        ),
      },
      {
        key: '1_6',
        question: 'Are there any gas fees?',
        answer: (
          <>
            <p>
              No, the NFTs are being minted on ImmutableX a Layer 2 scaling solution for Ethereum
              which has no gas fees.
            </p>
          </>
        ),
      },
      {
        key: '1_7',
        question: 'Is any of my personal data being stored?',
        answer: (
          <p>
            Your email address or wallet address will be used to facilitate the minting process.
            This data will be hashed and encrypted during minting and will not be retained long
            term. If you have any questions, email{' '}
            <a className="underline" href="mailto:trust@moonshotgaragelabs.com">
              trust@moonshotgaragelabs.com
            </a>
            .
          </p>
        ),
      },
      {
        key: '1_8',
        question: 'Where can I view my NFT?',
        answer: (
          <>
            <p>
              Your NFT is viewable on this microsite, on{' '}
              <a
                className="underline"
                href="https://market.immutable.com/"
                target="_blank"
                rel="noreferrer"
              >
                {"ImmutableX's"} marketplace
              </a>
              ,{' '}
              <a className="underline" href="https://rarible.com/" target="_blank" rel="noreferrer">
                Rarible
              </a>
              ,{' '}
              <a
                className="underline"
                href="https://mintable.app/"
                target="_blank"
                rel="noreferrer"
              >
                OKX
              </a>
              ,{' '}
              <a className="underline" href="https://nftrade.com/" target="_blank" rel="noreferrer">
                NFTrade
              </a>{' '}
              and other ImmutableX supported marketplaces.
            </p>
          </>
        ),
      },
      {
        key: '1_9',
        question: 'How do you know how much Carbon to offset?',
        answer: (
          <>
            <p>
              The average Australian family creates 9.5 tonnes of carbon emissions each year.{' '}
              {"That's"} 26kg/day. {"We're"} surrendering 26kg of carbon credits to cover your
              Christmas through an official Australian Carbon Credit Unit which has been tied to
              your NFT.
            </p>
          </>
        ),
      },
      {
        key: '1_10',
        question: 'What is an Australian Carbon Credit Unit (ACCU)?',
        answer: (
          <p>
            ACCUs are measurable, verifiable emission reductions from certified climate action
            projects, administered by the{' '}
            <a
              className="underline"
              href="https://www.cleanenergyregulator.gov.au/"
              target="_blank"
              rel="noreferrer"
            >
              Australian Clean Energy Regulator
            </a>
            . The project which produce these credits reduce, remove or avoid greenhouse gas
            emissions. Each ACCU represents 1 tonne of carbon abatement. For this project, Moonshot
            will acquired enough ACCUs to offset 26kg of carbon per NFT and will then surrender
            these credits to the Clean Energy Regulator.
          </p>
        ),
      },
      {
        key: '1_11',
        question: 'How many NFTs can I mint?',
        answer: (
          <>
            <p>One NFT can be minted per unique code.</p>
          </>
        ),
      },
      {
        key: '1_12',
        question: 'Is my NFT transferable or retradable?',
        answer: (
          <>
            <p>
              Yes, your NFTs can be transferred to other wallets or traded on ImmutableX supported
              exchanges.
            </p>
          </>
        ),
      },
    ],
  },
];

export const faqIntroNFT = faqTopics.find((topic) => topic.id === '1')!;

export const faqIndexAll = [...faqIntroNFT.items];

export const faqIndexLandingPage = [...faqIntroNFT.items];
