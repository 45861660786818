import { useQuery } from '@tanstack/react-query';
import { httpClient } from '@/lib/axios/httpClient';
import { queryClient } from '@/lib/config/queryClient';
import { MyNftsResponse } from '@/services/backend/types';

export async function invalidateMyNfts(wallet?: string) {
  return queryClient.invalidateQueries(['myNfts', wallet]);
}

const getMyNfts = async (): Promise<MyNftsResponse> => {
  const { data } = await httpClient.get('/api/user/nfts');
  return data;
};

export function useGetMyNfts(wallet?: string) {
  const { data: myNfts, status: myNftsStatus } = useQuery(['myNfts', wallet], getMyNfts, {
    enabled: wallet !== undefined,
    retry: true,
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
    staleTime: Infinity,
    refetchInterval: (data) => (data?.status === 'in_progress' ? 5 * 1000 : false),
  });

  return {
    myNfts,
    myNftsStatus,
    isMyNftsLoaded: myNftsStatus === 'success',
  };
}
