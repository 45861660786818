import 'reflect-metadata';
import '@/styles/globals.scss';
import 'react-loading-skeleton/dist/skeleton.css';

import { Poppins } from '@next/font/google';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { goerli, mainnet } from '@wagmi/core/chains';
import { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { Session } from 'next-auth';
import { SessionProvider, signOut } from 'next-auth/react';
import React, { ReactElement, ReactNode, useContext, useEffect, useState } from 'react';
import { configureChains, createClient, useAccount, useDisconnect, WagmiConfig } from 'wagmi';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { LoadingScreen } from '@/components/common/LoadingScreen';
import { AppContext, AppContextProvider } from '@/contexts/AppContext';
import { queryClient } from '@/lib/config/queryClient';
import { useSessionCustom } from '@/lib/hooks/useSessionCustom';
import { SESSION_REFETCH_INTERVAL_IN_SECONDS } from '@/lib/utils/sessionConstants';
import { isProduction, PrivateRoutes, PublicRoutes, Routes } from '@/types/types';
import * as ga from '../lib/ga';
import { isLocalEnvironment } from '../middleware';

const defaultChains = [isProduction ? mainnet : goerli];

const providers = [alchemyProvider({ apiKey: process.env.NEXT_PUBLIC_ALCHEMY_KEY })];
const { chains, provider } = configureChains(defaultChains, providers);

const client = createClient({
  autoConnect: true,
  connectors: [new MetaMaskConnector({ chains })],
  provider,
});

export type NextPageWithLayout<P = Record<string, unknown>> = NextPage<P> & {
  getLayout?: (page: ReactElement) => ReactNode;
  auth?: boolean;
};

type AppPropsWithLayout<P = Record<string, unknown>> = AppProps<P> & {
  Component: NextPageWithLayout;
};

// If loading a variable font, you don't need to specify the font weight
const poppins = Poppins({
  subsets: ['latin'],
  weight: ['400', '500', '600', '700'],
  style: ['normal', 'italic'],
  variable: '--font-poppins',
});

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const router = useRouter();
  const [pageViewLoaded, setPageViewLoaded] = useState(false);
  const isLocal = isLocalEnvironment();

  useEffect(() => {
    if (!document.body.classList.contains(poppins.variable)) {
      document.body.classList.add(poppins.variable);
    }
  }, []);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      console.log(`GA Disabled? ${isLocal} | handleRouteChange: ${url}`);
      if (!isLocal) {
        ga.pageview(url);
      }
    };
    //When the component is mounted, subscribe to router changes
    //and log those page views
    router.events.on('routeChangeComplete', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    if (!pageViewLoaded) {
      const url = router.route;
      console.log(`GA Disabled? ${isLocal} | PageView triggered: ${url}`);
      if (!isLocal) {
        ga.pageview(url);
      }
      setPageViewLoaded(true);
    }
  }, []);

  return (
    <div className={`${poppins.variable} font-sans`}>
      <WagmiConfig client={client}>
        <QueryClientProvider client={queryClient}>
          <SessionProvider
            session={pageProps?.session as Session}
            refetchInterval={SESSION_REFETCH_INTERVAL_IN_SECONDS}
          >
            <CryptoWalletListener>
              <AppContextProvider>
                <Auth>
                  <Component {...pageProps} />
                </Auth>
              </AppContextProvider>
            </CryptoWalletListener>
          </SessionProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </WagmiConfig>
    </div>
  );
}

interface AuthProps {
  children: ReactNode;
}

function Auth({ children }: AuthProps) {
  const router = useRouter();
  const { session, sessionStatus } = useContext(AppContext);

  useEffect(() => {
    void (async () => {
      if (
        PrivateRoutes.includes(router.pathname as Routes) &&
        sessionStatus !== 'loading' &&
        sessionStatus === 'unauthenticated'
      ) {
        await router.push(Routes.landing_page);
      }
    })();
  }, [session, sessionStatus]);

  if (
    (PrivateRoutes.includes(router.pathname as Routes) && sessionStatus !== 'authenticated') ||
    (PublicRoutes.includes(router.pathname as Routes) && sessionStatus === 'loading')
  ) {
    return <LoadingScreen />;
  }

  return <>{children}</>;
}

interface CryptoWalletListenerProps {
  children: ReactNode;
}

function CryptoWalletListener({ children }: CryptoWalletListenerProps) {
  const sessionCustom = useSessionCustom();
  const { disconnect } = useDisconnect();

  const { address } = useAccount({
    onConnect({ address, connector, isReconnected }) {
      console.log('CryptoWalletListener:Connected', { address, connector, isReconnected });
    },
    async onDisconnect() {
      console.log('CryptoWalletListener:Disconnected');
      if (sessionCustom.isSessionAuthenticated) {
        console.log('CryptoWalletListener:Disconnected:signOut');
        await signOut({
          callbackUrl: Routes.landing_page,
        });
      } else {
        window.location.reload();
      }
    },
  });

  useEffect(() => {
    if (
      sessionCustom.sessionStatus === 'authenticated' &&
      address &&
      sessionCustom.isLoginProviderWallet &&
      sessionCustom.session.walletAddress.toLowerCase() !== address.toLowerCase()
    ) {
      disconnect();
      console.log('disconnect:forced', sessionCustom.session.walletAddress, address);
    }
  }, [address, sessionCustom]);

  return <>{children}</>;
}

export default MyApp;
