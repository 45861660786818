import { ReactNode, useState } from 'react';
import IconArrowDown from '@/public/images/arrow-down.svg';
import IconArrowUp from '@/public/images/arrow-up.svg';

interface Props {
  question: string;
  answer?: ReactNode;
  children?: ReactNode;
}
export const Question = ({ question, answer, children }: Props) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  return (
    <>
      <div className="w-full">
        <div className="flex items-start">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setOpen((value) => !value);
            }}
            className="flex-1 font-semibold text-lg leading-7 xl:text-left"
          >
            {question}
          </a>
          <a
            href="#"
            className="ml-3 mt-3"
            onClick={(e) => {
              e.preventDefault();
              setOpen((value) => !value);
            }}
          >
            {isOpen ? (
              <IconArrowUp width={9.87} height={5.68} />
            ) : (
              <IconArrowDown width={9.87} height={5.68} />
            )}
          </a>
        </div>
        {isOpen && answer && (
          <div className="mt-4 text-text-300 text-left font-normal text-base leading-7">
            {answer}
          </div>
        )}
      </div>
      {isOpen && children && <>{children}</>}
    </>
  );
};
