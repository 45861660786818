// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import {Integrations} from '@sentry/tracing';
import {CaptureConsole as CaptureConsoleIntegration} from "@sentry/integrations";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const isLogsSentryEnabled = (/^true$/).test(process.env.NEXT_PUBLIC_SENTRY_LOGS_ENABLED);

if (isLogsSentryEnabled) {
    Sentry.init({
        dsn: SENTRY_DSN || 'https://987995b346e74cdfbe119f98d29590d0@o1306114.ingest.sentry.io/6548476',
        integrations: isLogsSentryEnabled ? [new Integrations.BrowserTracing(), new CaptureConsoleIntegration(
            {
                // array of methods that should be captured
                levels: ['log', 'info', 'warn', 'error', 'debug', 'assert']
            }
        )] : [new Integrations.BrowserTracing()],
        environment: process.env.NEXT_PUBLIC_CURRENT_ENV,
        // Adjust this value in production, or use tracesSampler for greater control
        tracesSampleRate: 1.0,
        // ...
        // Note: if you want to override the automatic release value, do not set a
        // `release` value here - use the environment variable `SENTRY_RELEASE`, so
        // that it will also get attached to your source maps
    });
}
