import { ReactNode } from 'react';

interface TextPrimaryProps {
  children: ReactNode;
  fontSize?: 'base' | 'lg' | 'xl';
}

export const TextPrimary = ({ fontSize = 'xl', ...props }: TextPrimaryProps) => {
  const fontClasses = () => {
    switch (fontSize) {
      case 'base':
        return 'tracking-wider text-base font-medium ';

      case 'lg':
        return 'font-bold text-2xl';

      case 'xl':
        return 'font-bold text-2xl 2md:text-6xl';

      default:
        return '';
    }
  };

  return (
    <span
      className={`
        bg-gradient-to-br from-primary-300 via-primary-600 to-secondary-600
        bg-clip-text text-transparent
        ${fontClasses()} 
      `}
      style={{
        paddingBottom: '0.2em',
        marginBottom: '-0.2em',
      }}
    >
      {props.children}
    </span>
  );
};
