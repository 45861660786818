import { useSession } from 'next-auth/react';
import { useEffect, useState } from 'react';
import { LoginProvider, SessionCustom } from '@/types/types';

export const useSessionCustom = () => {
  const { data: session, status: sessionStatus } = useSession();
  const sessionCustom = session as SessionCustom;
  const [isLoginProviderWallet, setIsLoginProviderWallet] = useState(false);

  useEffect(() => {
    setIsLoginProviderWallet(
      [LoginProvider.METAMASK, LoginProvider.COINBASE].includes(
        sessionCustom?.loginProvider as LoginProvider,
      ),
    );
  }, [sessionCustom]);

  return {
    session: sessionCustom,
    sessionStatus,
    isLoginProviderWallet,
    isSessionAuthenticated: sessionStatus === 'authenticated',
    isDisconnected: sessionStatus === 'unauthenticated',
    isLoading: sessionStatus === 'loading',
  };
};
