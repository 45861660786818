// log the pageview with their URL
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const pageview = (url) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window?.gtag?.('config', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
    page_path: url,
  });
};

// log specific events happening.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const event = ({ action, params }) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window?.gtag?.('event', action, params);
};
