import { createContext, ReactNode } from 'react';
import { useGetMyNfts } from '@/lib/hooks/nft';
import { useSessionCustom } from '@/lib/hooks/useSessionCustom';
import { MyNftsResponse } from '@/services/backend/types';
import { SessionCustom } from '@/types/types';

interface AppContextType {
  session: SessionCustom;
  sessionStatus: 'authenticated' | 'unauthenticated' | 'loading';
  myNfts?: MyNftsResponse;
  myNftsStatus: 'error' | 'success' | 'loading';
}

export const AppContext = createContext({} as AppContextType);

interface AppContextProviderProps {
  children: ReactNode;
}

export function AppContextProvider({ children }: AppContextProviderProps) {
  const { session, sessionStatus } = useSessionCustom();
  const { myNfts, myNftsStatus } = useGetMyNfts(session?.walletAddress);
  return (
    <AppContext.Provider value={{ session, sessionStatus, myNfts, myNftsStatus }}>
      {children}
    </AppContext.Provider>
  );
}
