import type { PropsWithChildren, ReactNode } from 'react';
import React from 'react';
import { FAQ } from '@/components/common/FAQ/FAQ.component';
import { Footer } from '@/components/common/Footer/Footer.component';
import { Steps, StepType } from '@/components/common/Steps/Steps.component';
import HeadMetaTags from '@/components/Head/HeadMetaTags';
import { Head } from '@/components/LandingPage/Head';

type BaseLayout = {
  currentStep: StepType;
  childrenHero?: ReactNode;
  childrenBox: ReactNode;
  childrenBottom?: ReactNode;
};

export default function BaseLayout({
  currentStep,
  childrenHero,
  childrenBox,
  childrenBottom,
}: PropsWithChildren<BaseLayout>) {
  const classNameContainer =
    'max-w-[1440px] mx-auto px-[28px] 2md:px-[80px] flex flex-col gap-[60px] md:gap-[80px]';

  return (
    <>
      <HeadMetaTags />
      <div className={`${classNameContainer}`}>
        <Head showLoginButton={currentStep !== 'login'} />
        {childrenHero}
      </div>

      <Steps currentStep={currentStep} />
      <div className={`${classNameContainer}`}>
        {childrenBox}

        {childrenBottom}
        <FAQ />
        <Footer />
      </div>
    </>
  );
}
