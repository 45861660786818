import { faqIndexLandingPage, FaqItem } from '@/components/Guide/faqTexts';
import { TextPrimary } from '@/components/TextPrimary/TextPrimary.component';
import { Question } from './Question/Question.component';

export const FAQ = () => {
  function getFaqQuestions(faqItems: FaqItem[]) {
    return (
      <div className="flex flex-col gap-6 3md:max-w-[614px] w-full">
        {faqItems.map(({ key, answer, question }) => (
          <div key={key} className="bg-white py-5 pb-5 px-6 rounded-lg">
            <Question key={key} question={question} answer={answer} />
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-10 py-10 px-3">
      <TextPrimary>FAQ</TextPrimary>

      <div className="flex flex-col 3md:flex-row gap-6 break-words justify-between">
        {getFaqQuestions(faqIndexLandingPage.slice(0, 6))}
        {getFaqQuestions(faqIndexLandingPage.slice(6))}
      </div>
    </div>
  );
};
