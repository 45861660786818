import type { NextRequest } from 'next/server';
import { NextResponse } from 'next/server';

export const tokenAllowedForName = 'X-PROJECT-NFT';
export const tokenAllowedForDevValue = `${process.env.TOKEN_ALLOWED_FOR_DEV}`;

const blockedUrl = '/_403';
// const ipRangeAllowed = process.env.IP_RANGE_ALLOWED;

// function isIpInsideOfRangeIp(request: NextRequest) {
//   const currentIp = request.ip;
//   if (!currentIp) {
//     if (!isDevEnvironment()) {
//       console.log('WARNING - request.ip is undefined');
//     }
//     return true;
//   }
//   if (!ipRangeAllowed) {
//     if (!isDevEnvironment()) {
//       console.log('WARNING - env "IP_RANGE_ALLOWED" is undefined');
//     }
//     return true;
//   }
//   return ipRangeCheck(currentIp, ipRangeAllowed.split(','));
// }

function isDevEnvironment() {
  return process.env.NEXT_PUBLIC_CURRENT_ENV === 'dev';
}

export function isLocalEnvironment() {
  return process.env.NEXT_PUBLIC_CURRENT_ENV === 'local';
}

function isAllowedUrl(request: NextRequest) {
  const pathname = new URL(request.url).pathname;
  return (
    pathname === blockedUrl ||
    pathname === '/auth.html' ||
    pathname === '/api/dev' ||
    pathname === '/api/auth/csrf' ||
    pathname === '/api/auth/session' ||
    pathname === '/api/auth/providers' ||
    pathname === '/api/auth/callback/credentials' ||
    pathname?.endsWith('/login')
  );
}

function isTokenAllowedForDev(request: NextRequest) {
  const currentToken =
    request.headers.get(tokenAllowedForName) ?? request.cookies.get(tokenAllowedForName)?.value;
  return currentToken === tokenAllowedForDevValue;
}

function isAllowedCallbackUrl(request: NextRequest) {
  const currentCallbackUrl = new URL(request.url).searchParams.get('callbackUrl');
  if (!currentCallbackUrl || currentCallbackUrl.startsWith('/')) {
    return true;
  }

  const allowedCallbackUrls = process.env.ALLOWED_CALLBACK_URLS.split(',') ?? [];
  const allAllowedCallbackUrls = [
    ...allowedCallbackUrls,
    ...allowedCallbackUrls.map((url) => `https://${url}`),
    ...allowedCallbackUrls.map((url) => `http://${url}`),
  ];

  return !!allAllowedCallbackUrls.find((allowedCallbackUrl) =>
    currentCallbackUrl.startsWith(allowedCallbackUrl),
  );
}

function newUrlWithoutCallbackUrlParam(request: NextRequest) {
  const newUrl = new URL(request.url);
  newUrl.searchParams.delete('callbackUrl');
  return newUrl;
}

export function middleware(request: NextRequest) {
  // Security: Client-side URL Redirect (WSTG-CLNT-04)
  if (!isAllowedCallbackUrl(request)) {
    const newUrl = newUrlWithoutCallbackUrlParam(request);
    return NextResponse.redirect(newUrl);
  }

  if (isLocalEnvironment() || isAllowedUrl(request)) {
    return NextResponse.next();
  }

  // Allow email images source
  if (request.nextUrl.pathname.startsWith('/images/email/')) {
    return NextResponse.next();
  }

  if (isDevEnvironment() && !isTokenAllowedForDev(request)) {
    return NextResponse.redirect(new URL(blockedUrl, request.url));
  }

  // if (!isIpInsideOfRangeIp(request)) {
  //   return NextResponse.redirect(new URL(blockedUrl, request.url));
  // }

  return NextResponse.next();
}

export const config = {
  matcher: ['/:path*'],
};
