import * as React from 'react';
import BaseLayout from '@/components/BaseLayout/BaseLayout';
import { CardSkeleton } from '@/components/common/Card';
import { InfoSkeleton } from '@/components/common/Info';

export function LoadingScreen() {
  return (
    <BaseLayout
      currentStep="loading"
      childrenHero={<InfoSkeleton />}
      childrenBox={<CardSkeleton />}
    />
  );
}
