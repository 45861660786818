import { ReactNode, useContext } from 'react';
import Skeleton from 'react-loading-skeleton';
import { AppContext } from '@/contexts/AppContext';
import styles from './Steps.module.css';

export type StepType = 'login' | 'mint' | 'reveal' | 'done' | 'none' | 'loading';

interface StepsProps {
  currentStep: StepType;
}

export function Steps({ currentStep }: StepsProps) {
  const appContext = useContext(AppContext);

  if (currentStep === 'none') {
    return null;
  }

  let srcStep1;
  let srcStep2;
  let srcStep3;

  let titleLabelStep1: unknown = 'Login';
  let titleLabelStep2: unknown = 'Mint';
  let titleLabelStep3: unknown = 'Reveal';

  let titleStyleStep1;
  let titleStyleStep2;
  let titleStyleStep3;

  const titleStyleActivated = '';
  const titleStyleDisabled = 'text-[#C4C4C4]';
  const titleStyleDone =
    'bg-gradient-to-br from-primary-300 via-primary-600 to-secondary-600 bg-clip-text text-transparent';

  const img = (src: string) => (
    <img
      src={src}
      alt=""
      className="w-[60px] h-[60px] md:w-[64px] md:h-[64px]"
      style={{
        filter: 'drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15))',
      }}
    />
  );
  let lineWidth = '';

  switch (currentStep) {
    case 'login':
      srcStep1 = img('/images/icon-step-1.png');
      srcStep2 = img('/images/icon-step-2.png');
      srcStep3 = img('/images/icon-step-3.png');

      titleStyleStep1 = titleStyleActivated;
      titleStyleStep2 = titleStyleDisabled;
      titleStyleStep3 = titleStyleDisabled;

      lineWidth = 'step1';
      break;
    case 'mint':
      srcStep1 = img('/images/icon-step-check.png');
      srcStep2 = img('/images/icon-step-2-active.png');
      srcStep3 = img('/images/icon-step-3.png');

      titleStyleStep1 = titleStyleDone;
      titleStyleStep2 = titleStyleActivated;
      titleStyleStep3 = titleStyleDisabled;

      lineWidth = 'step2';
      break;
    case 'reveal':
      srcStep1 = img('/images/icon-step-check.png');
      srcStep2 = img('/images/icon-step-check.png');
      srcStep3 = img('/images/icon-step-3-active.png');

      titleStyleStep1 = titleStyleDone;
      titleStyleStep2 = titleStyleDone;
      titleStyleStep3 = titleStyleActivated;

      lineWidth = 'step3';

      if (appContext.myNfts?.status !== 'not_found' && !appContext.myNfts?.canSendInvitations) {
        srcStep3 = img('/images/icon-step-check.png');
        titleStyleStep3 = titleStyleDone;
        lineWidth = 'step4';
      }

      break;

    case 'loading':
      srcStep1 = (
        <Skeleton circle width={60} height={60} baseColor="#d6d6d6" highlightColor="#ecebeb" />
      );
      srcStep2 = (
        <Skeleton circle width={60} height={60} baseColor="#d6d6d6" highlightColor="#ecebeb" />
      );
      srcStep3 = (
        <Skeleton circle width={60} height={60} baseColor="#d6d6d6" highlightColor="#ecebeb" />
      );

      titleStyleStep1 = titleStyleDisabled;
      titleStyleStep2 = titleStyleDisabled;
      titleStyleStep3 = titleStyleDisabled;

      titleLabelStep1 = (
        <Skeleton width="60" height="20" baseColor="#d6d6d6" highlightColor="#ecebeb" />
      );
      titleLabelStep2 = (
        <Skeleton width="60" height="20" baseColor="#d6d6d6" highlightColor="#ecebeb" />
      );
      titleLabelStep3 = (
        <Skeleton width="60" height="20" baseColor="#d6d6d6" highlightColor="#ecebeb" />
      );

      lineWidth = 'step0';
      break;
    default:
      srcStep1 = img('/images/icon-step-check.png');
      srcStep2 = img('/images/icon-step-check.png');
      srcStep3 = img('/images/icon-step-check.png');

      titleStyleStep1 = titleStyleDone;
      titleStyleStep2 = titleStyleDone;
      titleStyleStep3 = titleStyleDone;

      lineWidth = 'step4';
      break;
  }

  const Icon = ({
    src,
    title,
    titleStyle,
  }: {
    src: ReactNode;
    title: string | ReactNode;
    titleStyle: string;
  }) => (
    <div className="flex flex-col items-center gap-2">
      <div>{src}</div>
      <div className={`font-bold ${titleStyle}`}>{title}</div>
    </div>
  );

  return (
    <div
      className={`max-w-full
        my-[80px]
        flex
        flex-col
        relative
      `}
    >
      <div className="flex justify-around max-w-[768px] w-full mx-auto z-20">
        <Icon src={srcStep1} titleStyle={titleStyleStep1} title={titleLabelStep1 as ReactNode} />
        <Icon src={srcStep2} titleStyle={titleStyleStep2} title={titleLabelStep2 as ReactNode} />
        <Icon src={srcStep3} titleStyle={titleStyleStep3} title={titleLabelStep3 as ReactNode} />
      </div>
      <div
        className="w-full z-0"
        style={{
          position: 'absolute',
          borderTop: '2px solid #c4c4c4',
          top: '32px',
        }}
      ></div>
      <div
        className={`bg-gradient-to-br from-primary-300 via-primary-600 to-secondary-600 z-10 ${styles[lineWidth]} ${styles.steps}`}
      ></div>
    </div>
  );
}
